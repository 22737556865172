import { on_ready, queue_tasks, is_elementor_edit } from 'js/utils';
import { retina_init, dynamic_load_trigger } from 'js/functions';
import Toggle_Height from 'classes/Toggle_Height';
import header_sticky_init from 'modules/header/header_sticky_init';
import forms_init from 'modules/forms/forms';
import carousels_init from 'modules/carousel/carousel';
import sticky_footer_cta_init from 'modules/footer/sticky_footer_cta/init';
import nav_menu_init from 'modules/menu/nav/nav';

// load global styles
import './main.scss';

on_ready(()=>{

    // scripts to load on elementor edit screen
    sticky_footer_cta_init();
    
    if( is_elementor_edit() ) {
        return; // elementor edit screen: do not run scripts
    }
    
    queue_tasks([

        // sticky header
        header_sticky_init,

        // nav menu: hover intent
        nav_menu_init,

        // forms ui + gravityforms customizations
        forms_init,

        // swiper carousel
        carousels_init,

        // load @2x images
        retina_init,
        
        // toggle element height from 0 to default - for animating height
        toggle_height_init,

        // mobile related functionalities
        on_mobile,
    ]);

    // search popup - remove if not used
    // dynamic_load_trigger({
    //     triggers: '.search_popup_open',
    //     event: 'click',
    //     load: ()=>{
    //         import('modules/search/search_popup/search_popup.scss');
    //         import('modules/search/search_popup/search_popup');
    //     }
    // })

    // tour race search popup - remove if not used
    dynamic_load_trigger({
        triggers: '.search_popup_open',
        event: 'click',
        load: ()=>{
            import('modules/search_tour_race/search_popup/search_popup.scss');
            import('modules/search_tour_race/search_popup/search_popup');
        }
    })

    // slide/mobile menu - remove if not used
    dynamic_load_trigger({
        triggers: '.slide_menu_open',
        event: 'click',
        load: ()=>{
            import('modules/menu/slide_menu/slide_menu.scss');
            import('modules/menu/slide_menu/slide_menu');
        }
    })

    // wishlist & compare - remove if not used
    if( document.querySelector('.compare_count') ||
        document.querySelector('.wishlist_count') ) {
        import('modules/save_items/init');
    }
    
    // cookie consent
    // if( !getCookie('ff_cookie_consent') ) {
    //     import('modules/cookie_consent/simple/simple')
    // }
});

function on_mobile(){
    if( window.innerWidth > 768 ) return;
    close_footer_dropdowns();
}

function toggle_height_init(){
    document.querySelectorAll('.ff_toggle_height').forEach(container=>{
        
        if( typeof container.ff_toggle_height !== 'undefined' ) return;

        const trigger = container.querySelector('.ff_toggle_height_trigger');
        const target = container.querySelector('.ff_toggle_height_target');
        if( !trigger || !target ) return;
        
        container.ff_toggle_height = new Toggle_Height({
            trigger,
            target,
        });
    })
}

function close_footer_dropdowns(){
    document.querySelectorAll('.elementor-location-footer .ff_toggle_height_trigger.open').forEach(dropdown=>{
        dropdown.click();
    })
}